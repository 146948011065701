const ApiRouteList = {
    GET_APP_INFO: 'app-info',
    ADMIN: 'admin',
    USER: 'user',
    USERS : 'users',
    COURSE: `course`,
    APPS: 'apps',
    TRAINING_CONTENT: `trainingContent`,
    SUBSCRIPTION: `courseSubscription`,
    FORGET_PASSWORD: `forgotPassword`,
    RESET_PASSWORD: `resetPassword`,
    CHANGE_PASSWORD: `change-password`,
    GET_AUTH_CODE: `get-auth-code`,
    VERIFY_AUTH_CODE: `verify-auth-code`,
    SUBSCRIBED_COURSE : 'subscribedCourse',
    SINGLE_SUBSCRIBED_COURSE : 'subscribedCourse/:id',
    CONTENT_COMPLETED : 'contentCompleted'
};
export default ApiRouteList;