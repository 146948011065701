import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    firstName: '',
    lastName: '',
}

const isUserInfo = createSlice({
    name: "userInfo",
    initialState: initialState,
    reducers: {
        onSetUserInfo: (state: { firstName: string, lastName: string },
            action: { payload: { firstName: string, lastName: string } }) => {
                state.firstName = action.payload.firstName;
                state.lastName = action.payload.lastName;
        },
        onClearUserInfo: (state: { firstName: string, lastName: string }) =>{
            state.firstName = '';
            state.lastName = '';
        }
    }
})

export const { onSetUserInfo, onClearUserInfo } = isUserInfo.actions;
export default isUserInfo.reducer;