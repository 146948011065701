import React, { useState } from "react";
import { useFormik } from "formik";
import { Button, Dropdown, Form, Input, Modal } from "semantic-ui-react";
import {
  CANCEL,
  ERRORS,
  PROFILE,
  SAVE,
  SERVER_ERROR,
  UPDATE,
} from "../constants/Constants";
import * as Yup from "yup";
import { getUserProfile } from "../services/user.service";
import { UpdateProfileType } from "../constants/enum";
import { toast } from "react-toastify";
import instance from "../services/api/index.service";
import AppRouteList from "../constants/ApiRoute.constant";
import { APP_SEC } from "../constants/enum";
import CryptoJSService from "../services/CryptoSecurity.service";
import Storage from "../services/localStorage";
import { useDispatch } from "react-redux";
import { onSetUserInfo } from "../_redux/slices/isUserInfo";

const UpdateProfile = () => {
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState<Boolean>(false);
  const dispatch = useDispatch();
  const CreateUserSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(ERRORS.firstName.required)
      .max(48, ERRORS.firstName.max),
    lastName: Yup.string()
      .required(ERRORS.lastName.required)
      .max(48, ERRORS.lastName.max),
    email: Yup.string()
      .required(ERRORS.email.required)
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: CreateUserSchema,
    onSubmit: (values: UpdateProfileType) => updateProfileApi(values),
  });

  const updateProfileApi = async (values: UpdateProfileType) => {
    try {
      const userInfo = getUserProfile();
      const { data, status } = await instance.patch<APP_SEC>(
        `${AppRouteList.USER}/${userInfo._id}`,
        {
          firstName: values.firstName.trim(),
          lastName: values.lastName.trim(),
        }
      );
      if (status === 200) {
        userInfo.firstName = values.firstName.trim(),
          userInfo.lastName = values.lastName.trim(),
          Storage.setUserData(CryptoJSService.encryptText(JSON.stringify(userInfo)));
        dispatch(onSetUserInfo({ firstName: userInfo.firstName, lastName: userInfo.lastName, }));
        toast.success(data.message);
        formik.resetForm();
        setOpen(false);
      }
    } catch (err: any) {
      const { response } = err;
      toast.error(response.data.message || SERVER_ERROR);
    }
  };

  const onEditProfile = () => {
    const userInfo = getUserProfile();
    formik.setValues({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
    })
  }

  return (
    <Modal
      size={"tiny"}
      trigger={<Dropdown.Item onClick={() => onEditProfile()}>{`${UPDATE} ${PROFILE}`}</Dropdown.Item>}
      header={`${UPDATE} ${PROFILE}`}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => {
        setOpen(true);
      }}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      content={
        <Form style={{ textAlign: "start", padding: "10px" }} onSubmit={formik.handleSubmit}>
          <Form.Field>
            <Input
              fluid
              icon="user"
              autoComplete="off"
              iconPosition="left"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
            />
            {isSubmit && formik.errors.firstName && (
              <span className="required">{formik.errors.firstName}</span>
            )}
          </Form.Field>
          <Form.Field>
            <Input
              fluid
              icon="user"
              autoComplete="off"
              iconPosition="left"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
            />
            {isSubmit && formik.errors.lastName && (
              <span className="required">{formik.errors.lastName}</span>
            )}
          </Form.Field>
          <Form.Field>
            <Input
              fluid
              icon="mail"
              autoComplete="off"
              iconPosition="left"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              readOnly
            />
          </Form.Field>
          <div className="buttons">
            <Button
              style={{ width: "100px" }}
              type="button"
              color="red"
              fluid
              size="large"
              onClick={() => setOpen(false)}
            >
              {CANCEL}
            </Button>
            <Button
              style={{ width: "100px" }}
              type="submit"
              color="green"
              fluid
              size="large"
              onClick={() => setIsSubmit(true)}
            >
              {SAVE}
            </Button>
          </div>
        </Form>
      }
    />
  );
};

export default UpdateProfile;
