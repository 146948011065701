import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Dropdown,
  Menu,
  Visibility
} from 'semantic-ui-react';
import logo from '../assets/logo.png'
import { LOGOUT, LOG_OUT_SUCCESS, MANAGE_2F_AUTH } from '../constants/Constants';
import RouteList from '../constants/Routes.constant';
import { logOutAndNavigate } from '../services/user.service';
import ChangePassword from './ChangePassword';
import UpdateProfile from './UpdateProfile';
const StickyHeader = (props: {
  dispatch: any,
  navigate?: any
}) => {
  const { isUserInfo, isAuth } = useSelector((item: any) => item);
  const navigate = useNavigate();
  const [menuFixed, setmenuFixed] = useState(false);
  const stickTopMenu = () => setmenuFixed(true);
  const unStickTopMenu = () => setmenuFixed(false);

  const logOut = () => {
    props = { ...props, navigate }
    toast.success(LOG_OUT_SUCCESS);
    logOutAndNavigate(props);
  }

  return (
    <>
      <Visibility
        onBottomPassed={stickTopMenu}
        onBottomVisible={unStickTopMenu}
        once={false}
      >
        <Menu
          borderless
          fixed={menuFixed ? "top" : undefined}
          style={{ height: '70px', padding: '10px 5%', position: 'relative',background: (location.hostname.includes('sandbox')) ? 'yellow' : '#fff' }}>
          <Link to="/">
            <img src={logo} title='ISOP LMS' height={'50px'} /></Link>
          {isAuth && isAuth.value &&
            <Menu.Menu position="right">
              <Menu.Item
                style={{ paddingTop: '15px', fontWeight: 'Bold', cursor: 'pointer' }}
                name='My Transcript'
                onClick={() => navigate(`/dashboard${RouteList.TRANSCRIPT}`)}
              />
              <p style={{ paddingTop: '15px' }}><b>Hi {isUserInfo.firstName}!</b></p>
              <Dropdown icon="user" pointing className="link item text-white icon-large">
                <Dropdown.Menu>
                  <UpdateProfile />
                  <ChangePassword />
                  <Dropdown.Item onClick={() => navigate(`${RouteList.DASHBOARD}${RouteList.TWO_FA}`)}>{MANAGE_2F_AUTH}</Dropdown.Item>
                  <Dropdown.Item onClick={() => logOut()}>
                    {LOGOUT}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Menu>
          }
        </Menu>
      </Visibility>
    </>
  )
}

export default StickyHeader