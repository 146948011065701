const RouteList = {
    ROOT: '/',
    LOGIN_PAGE: '/login',
    CHANGE_PASSWORD: '/change-password',
    FORGOT_PASSWORD: '/forgot-password',
    DASHBOARD: '/dashboard',
    NOT_FOUND: '*',
    RESET_PASSWORD: '/reset-password/:token',
    FORGOTPASSWORDSUCCESS: '/forgot-password-success',
    TWO_FA: `/two_factor_auth`,
    USER_COURSE : '/user-courses/:id',
    LMS_DASHBOARD : '/LMS-dashboard',
    TRANSCRIPT : '/transcript',
    USER_COURSE_FN: (id: string) => `/user-courses/${id}`,
}

export default RouteList

